<template>
  <div class="container">
    <div class="userInfo">
      <div class="account">
        <span>{{ userInfo.NickName }}</span
        ><van-icon name="edit" size="16" @click="showEdit" />
      </div>
      <div class="username">
        <span v-if="userInfo.RealName">{{ userInfo.RealName }}</span>
        <span v-else>{{ $t('me').Unauthenticated }}</span>
        ({{ $t('me').CreditScore }}：{{ userInfo.Credit }})
      </div>
      <div class="amount">
        <span>{{ basicConfig.Symbol }} {{ userInfo.Balance }}</span>
      </div>
      <div class="level">
        <img :src="userInfo.GradeImge" alt="" />
        <span>{{ userInfo.GradeName }}</span>
      </div>
    </div>
    <div class="meaus">
      <router-link class="item" to="Recharge">
        <img src="@/assets/recharge.png" alt="" />
        <span>{{ $t('me').Inpour }}</span>
      </router-link>
      <router-link class="item" to="With">
        <img src="@/assets/with.png" alt="" />
        <span>{{ $t('me').Withdrawal }}</span>
      </router-link>
    </div>
    <van-cell
      v-if="basicConfig.IsCashProfit"
      @click="goRouter('Team')"
      :title="$t('me').Team"
      icon="friends-o"
      is-link
    />
    <van-cell
      @click="goRouter('AccessLog')"
      :title="$t('me').AccessLog"
      icon="balance-pay"
      is-link
    />
    <!-- <van-cell
      @click="goRouter('AmountChange')"
      :title="$t('me').FundingDetails"
      icon="balance-list-o"
      is-link
    /> -->
    <van-cell
      @click="goRouter('HistoryOrder')"
      :title="$t('me').HistoryOrder"
      icon="balance-list-o"
      is-link
    />
    <van-cell
      @click="goRouter('Bank')"
      :title="$t('me').BankCard"
      icon="debit-pay"
      is-link
      v-if="basicConfig.IsBank"
    />
    <van-cell
      @click="goRouter('SWIFTCode')"
      title="SWIFT Code"
      icon="debit-pay"
      is-link
      v-else
    />
    <van-cell
      @click="goRouter('Authentication')"
      :title="$t('me').RealName"
      v-if="!userInfo.IsReal&&userInfo.AuthStatus != 'wait'"
      :value="$t('me').unread"
      icon="debit-pay"
      is-link
    />
    <van-cell
      @click="goRouter('')"
      v-else
      :title="$t('me').RealName"
      :value="userInfo.IsReal?$t('me').read:$t('statusLang').Review"
      icon="debit-pay"
      is-link
    />
    <van-cell
      @click="goRouter('ask')"
      :title="$t('me').ChangePassword"
      icon="setting-o"
      is-link
    />
    <van-cell
      @click="goRouter('ask')"
      :title="$t('me').wlPassword"
      icon="passed"
      v-if="userInfo.IsCashPassword"
      is-link
    />
    <van-cell
      v-else
      @click="goRouter('EditWithPassword')"
      :title="$t('me').wlPassword"
      icon="passed"
      is-link
    />
    <van-cell
      @click="goRouter('Share')"
      :title="$t('me').Share"
      icon="thumb-circle-o"
      is-link
      v-if="basicConfig.IsShowShare"
    />
    <van-cell
      @click="goRouter('lang')"
      :title="$t('me').Language"
      icon="warning-o"
      is-link
    />
    <van-button type="danger" size="large" @click="signOut">
      {{ $t('me').Logout }}</van-button
    >
    <TabBar :active="active" />
    <van-dialog
      v-model="show"
      :title="$t('me').ModifyNickname"
      show-cancel-button
      :confirmButtonText="$t('me').nickNakeConfirmBtn"
      :cancelButtonText="$t('me').nickNakeCancelBtn"
      @confirm="handleEdit"
    >
      <van-field v-model="newNickName" :placeholder="$t('me').placeholder" />
    </van-dialog>
    <van-popup v-model="showShare" position="bottom" class="share-popup">
      <img src="@/assets/share.jpg" alt="" />
      <div class="close" @click="showShare = false">
        <van-icon name="arrow-down" />
        <div>{{ $t('me').close }}</div>
      </div>
      <p>{{ $t('me').codeText }}</p>
      <div class="cord-box">
        <qrcode-vue
          :value="link"
          :size="size"
          level="H"
          class="qrcode"
        ></qrcode-vue>
        <div>{{ $t('me').ShareText }}</div>
      </div>
      <van-button round type="info" @click="getShareLink">{{
        $t('me').copyLink
      }}</van-button>
    </van-popup>
    <Language :showLanguage="showLanguage" @closePopup="showLanguage = false" />
    <span id="InvitationCode">{{ link }}</span>
  </div>
</template>

<script>
import TabBar from '@/components/tabBar'
import Language from '@/components/language'
import QrcodeVue from 'qrcode.vue'
import userApi from '@/api/user'
import { EventBus } from '@/utils/event-bus'
import { Token } from '@/utils/mm'
import { mapGetters } from 'vuex'
export default {
  components: {
    TabBar,
    Language,
    QrcodeVue,
  },
  data() {
    return {
      active: 'me',
      userInfo: {},
      link: '',
      show: false,
      showLanguage: false,
      showShare: false,
      size: 200,
      newNickName: null,
    }
  },
  computed: {
    ...mapGetters(['basicConfig']),
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
      this.link =
        location.origin +
        '/#/Register?InvitationCode=' +
        this.userInfo.InvitationCode
    },
    async handleEdit() {
      if (this.newNickName === '') {
        this.$toast({
          message: this.$t('me').close,
          position: 'bottom',
        })
        return
      }
      await userApi.editNickName({
        name: this.newNickName,
      })
      this.$toast(this.$t('me').success)
      this.newNickName = ''
      this.getUserInfo()
    },
    goRouter(routerName) {
      if (routerName === 'ask') {
        this.$toast({
          position: 'bottom',
          message: this.$t('me').service,
        })
        return
      }
      if (!routerName) {
        return
      }
      if (routerName === 'lang') {
        this.showLanguage = true
      } else {
        this.$router.push({ name: routerName })
      }
    },
    showEdit() {
      this.show = true
    },
    signOut() {
      Token.remove()
      this.$router.push({ name: 'Login' })
      EventBus.$emit('Logout')
    },
    getShareLink() {
      const range = document.createRange()
      range.selectNode(document.getElementById('InvitationCode'))
      const selection = window.getSelection()
      if (selection.rangeCount > 0) selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeRange(range)
      this.$toast({
        type: 'success',
        message: '复制成功',
        className: 'message',
        duration: '1000',
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
@import "~@/styles/mixin.styl";
.container
  width: 100%;
  background-color #1f1f1f
  font-size 12px
  color #ebe5cd
  overflow-x hidden
  height 100vh
  padding-bottom 50px
  .userInfo
    text-align center
    padding 18px 16px
    height 200px
    background url('~@/assets/userinfo.jpg')
    background-size 100% 100%
    position relative
    .account
      font-size 15px
      text-align left
      display flex
      align-items center
      span
        margin-right 5px
    .username
      margin 30px 0
      span
        font-size 20px
    .amount
      font-size 14px
      span
        font-size 21px
        margin-right 5px
    .level
      position absolute
      bottom 20px
      right 10px
      flex-column()
      img
        width 50px
        margin-bottom 5px
  .meaus
    height 62px
    display flex
    border-bottom: 2Px solid #000;
    .item
      flex-column()
      flex 1
      margin 10px 0
      color #fae39c
      font-size 14px
      img
        margin-bottom 5px
        width 25px
        height 25px
      &:nth-of-type(1)
        border-right 1Px solid #443f3f
  .van-cell
    background: #1f1f1f;
    color: #ebe5cd;
    &:not(:last-child):after
      border-bottom: 1px solid #40404b;
    >>> .van-cell__title
      span
        width 190px
        display inline-block
  .van-button
    height 40px
    margin 20px 15px
    width calc(100% - 30px)
    font-size 14px
  .van-dialog
    background #1F1F1F
    >>> .van-field__control
      color #fff
    >>> .van-button--default
      background #1F1F1F
      color #ebe5cd!important
  .share-popup
    color #fff
    height 540px
    background #1f1f1f
    text-align center
    .close
      flex-column()
      z-index 9
      font-size 14px
      line-height 1.5
    img
      height 350px
      position absolute
      left 0
      top 0
      z-index -1
    p
      font-weight bold
      font-size 19px
      margin 38px 0 26px
    .cord-box
      border-radius 10px
      box-shadow 0 0 5px #fff
      background #ffffff
      width 250px
      padding 10px
      height 290px
      color #000
      font-size 23px
      margin  0 auto
      flex-column()
      div
        margin-top 15px
    .van-button
      width 300px
      height 44px
      background: linear-gradient(to right, rgb(248, 94, 92), rgb(254, 188, 41));
      border: 0px;
      margin-top 40px
  #InvitationCode {
    position absolute
    left -100vw
  }
</style>
